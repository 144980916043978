// StatusCard.js
import React, { useState } from 'react';
import './component.css'
import editImg from "../assets/images/edit-green.svg";
import clinicImg from '../assets/images/clinic-booking.svg';
import videoImg from '../assets/images/video-booking.svg'
import ScheduleModal from '../Doctor-Dashboard/ScheduleModal';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const ScheduleCard = ({ total, booked, available, clinic, video, time, scheduleId, onScheduleDeleted  }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [existingSchedule, setExistingSchedule] = useState(null);
  const [scheduleData, setScheduleData] = useState([]);
  
  const handleOpenModal = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/docter/getscheduleById?scheduleId=${scheduleId}`);
      setExistingSchedule(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch schedule data', error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
  
      if (result.isConfirmed) {
        await axios.post(`http://localhost:5000/docter/deleteSchedule`, { id: scheduleId });
        Swal.fire(
          'Deleted!',
          'Your schedule has been deleted.',
          'success'
        );
        onScheduleDeleted();
      }
    } catch (error) {
      console.error('Failed to delete schedule', error);
      Swal.fire(
        'Error!',
        'There was an issue deleting your schedule.',
        'error'
      );
    }
  };  

  const fetchScheduleData = async () => {
    try {
        const response = await axios.get(`http://localhost:5000/docter/get`, {
            params: { id: Number(localStorage.getItem('docId'))}
        });
        if (response.data) {
            setScheduleData(response.data);
        } else {
            console.error('Unexpected API response format');
        }
    } catch (error) {
        console.error('Failed to fetch schedule', error);
    }
};

  return (
    <div className="schedule-card">
      <div className='d-flex justify-content-between align-items-center'>
        <h3>{time}</h3>
        <div className='icon-container'>
          <img src={editImg} className='mediumIcon' onClick={handleOpenModal} alt="Edit" />
          <FontAwesomeIcon icon={faTrash} className='mediumIcon' onClick={handleDelete} style={{ color: '#5c9a41', marginLeft: '10px', cursor: 'pointer', fontSize: '22px' }} />
        </div>
      </div>

      <ul className='schedule-ul'>
        <li><strong>Total Appointments</strong> <b>{total}</b></li>
        <li><strong>Booked</strong> {booked}</li>
        <li><strong>Available Slots</strong> {available}</li>
        <li><div className='d-flex justify-content-between align-items-center'><img src={clinicImg} className='smallIcon' /><strong>Clinic</strong></div> {clinic}</li>
        <li><div className='d-flex justify-content-between align-items-center'><img src={videoImg} className='smallIcon' /><strong>Video</strong></div> {video}</li>
      </ul>
      <ScheduleModal isOpen={isModalOpen} existingSchedule={existingSchedule} isEditMode={true} onClose={handleCloseModal} onSuccess={fetchScheduleData}/>
    </div>

  );
};

export default ScheduleCard;