import React, { useEffect, useState, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import calendarImg from "../assets/images/ScheduleCalender.svg";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './scheduleModal.css'

const ScheduleModal = ({ isOpen, onClose, existingSchedule, isEditMode, onSuccess }) => {
    const [doctorId, setDoctorId] = useState('');
    const [date, setDate] = useState(null); // Initialize as null
    const [workingHours, setWorkingHours] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [getWorkingHours, setGetWorkingHours] = useState([]);
    const [showWorkingHoursDropdown, setShowWorkingHoursDropdown] = useState(false);
    const [showAvailableSlotsDropdown, setShowAvailableSlotsDropdown] = useState(false);
    const [selectedWorkingHours, setSelectedWorkingHours] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [newSlots, setNewSlots] = useState({});

    const modalContentRef = useRef(null);

    const today = new Date();

    useEffect(() => {
        // Get doctorId from localStorage
        const id = localStorage.getItem('docId');
        if (id) {
            setDoctorId(id);
        }
        if (isOpen) {
            axios.get('http://localhost:5000/workinghours/records')
                .then(response => {
                    if (response.data) {
                        setGetWorkingHours(response.data);
                    } else {
                        console.error('Unexpected API response format');
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch working hours', error);
                });
        }
        if (isEditMode && existingSchedule) {
            setDate(new Date(existingSchedule.date));
            const time = existingSchedule.info.time;
            setWorkingHours([time]);
            setSelectedWorkingHours([time]);
            setAvailableSlots([{ hour: time, slot: existingSchedule.info.totalslots }]);
        }
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isOpen, onClose]);

    const handleBackdropClick = (e) => {
        if (modalContentRef.current && !modalContentRef.current.contains(e.target)) {
            onClose();
        }
    };

    const handleWorkingHoursChange = (hour) => {
        setSelectedWorkingHours((prev) =>
            prev.includes(hour)
                ? prev.filter((h) => h !== hour)
                : [...prev, hour]
        );
    };

    const handleDoneWorkingHours = () => {
        setWorkingHours(selectedWorkingHours);
        setShowWorkingHoursDropdown(false);
    };

    const handleSlotsChange = (hour, value) => {
        setNewSlots({ ...newSlots, [hour]: value });
    };

    const handleDoneAvailableSlots = () => {
        const slots = Object.keys(newSlots).map((hour) => ({
            hour,
            slot: Number(newSlots[hour]),
        }));
        setAvailableSlots(slots);
        setShowAvailableSlotsDropdown(false);
    };

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {

            const createData = {
                doctorId: Number(doctorId),
                date: date.toISOString(),
                workingHours,
                availableSlots: availableSlots.map((slot) => slot.slot)
            };

            const updateData={}

            if(isEditMode){
                updateData = {
                    id: existingSchedule.info._id,
                    totalslots: availableSlots[0].slot,
                };
            }

            const dataToSend = isEditMode ? updateData : createData;

            const response = isEditMode
                ? await axios.post(`http://localhost:5000/docter/updateSchedule`, dataToSend)
                : await axios.post('http://localhost:5000/docter/schedule', dataToSend);

            if (response.data.status === 'success') {
                alert(`Schedule ${isEditMode ? 'updated' : 'created'} successfully!`);
                onSuccess();
                onClose();
            } else {
                alert(`Error ${isEditMode ? 'updating' : 'creating'} schedule`);
            }
        } catch (error) {
            console.error(`Failed to ${isEditMode ? 'update' : 'create'} schedule`, error);
            alert(`Error ${isEditMode ? 'updating' : 'creating'} schedule`);
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={handleBackdropClick}>
            <div className="modal-content" ref={modalContentRef}>
                <button className="modal-close btn btn-danger" onClick={onClose}>
                    <FaTimes />
                </button>
                <h2>{isEditMode ? "Update Schedule" : "Create Schedule"}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3 position-relative" style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: 'flex-start' }}>
                        <label htmlFor="date" className="form-label">Date</label>
                        <div className="date-picker-container">
                            <DatePicker
                                selected={date}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                className="form-control date-picker"
                                placeholderText="Select a date"
                                minDate={today}
                                disabled={isEditMode}
                            />
                            <img src={calendarImg} alt="Calendar" className="calendar-icon" />
                        </div>
                    </div>
                    <div className="mb-3">
                        {!isEditMode && (<div className="input-group">
                            <label htmlFor="workingHour" className="form-label">Working Hours</label>
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setShowWorkingHoursDropdown(!showWorkingHoursDropdown)}
                            >
                                Select Working Hours
                            </button>
                        </div>
                        )}
                        {showWorkingHoursDropdown && (
                            <div className="dropdown-checklist">
                                {getWorkingHours.length > 0 ? (
                                    getWorkingHours.map((hour) => (
                                        <div key={hour._id} className="d-flex align-items-center mb-2">
                                            <div className="checkbox-container me-2">
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${hour.name}`}
                                                    checked={selectedWorkingHours.includes(hour.name)}
                                                    onChange={() => handleWorkingHoursChange(hour.name)}
                                                />
                                                <label htmlFor={`checkbox-${hour.name}`} className="custom-checkbox"></label>
                                            </div>
                                            <label htmlFor={`checkbox-${hour.name}`} className="checkbox-label">{hour.name}</label>
                                        </div>
                                    ))
                                ) : (
                                    <p>No working hours available</p>
                                )}
                                <button type="button" className="btn btn-primary" onClick={handleDoneWorkingHours}>
                                    Done
                                </button>
                            </div>
                        )}
                        <ul className="list-group mt-2" style={{ overflow: 'auto', maxHeight: '150px' }}>
                            {workingHours.map((hour, index) => (
                                <li key={index} className="list-group-item">{hour}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="availableSlot" className="form-label">Available Slots</label>
                        <div className="input-group">
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setShowAvailableSlotsDropdown(!showAvailableSlotsDropdown)}
                            >
                                Select Available Slots
                            </button>
                        </div>
                        {showAvailableSlotsDropdown && (
                            <div className="dropdown-checklist">
                                {workingHours.length > 0 ? (
                                    workingHours.map((hour) => (
                                        <div key={hour} className="d-flex align-items-center mb-2">
                                            <span className="ms-2">{hour}:</span>
                                            <input
                                                type="number"
                                                min="0"
                                                style={{ marginLeft: '20px', borderRadius: '20px', paddingLeft: '15px', width: '80px', textAlign: 'center' }}
                                                className="ms-2"
                                                value={newSlots[hour] || ''}
                                                onChange={(e) => handleSlotsChange(hour, e.target.value)}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <p>Select working hours first</p>
                                )}
                                <button type="button" className="btn btn-primary" onClick={handleDoneAvailableSlots}>
                                    Done
                                </button>
                            </div>
                        )}
                        <ul className="list-group mt-2" style={{ overflow: 'auto', maxHeight: '150px' }}>
                            {availableSlots.map((slot, index) => (
                                <li key={index} className="list-group-item">{`Hour: ${slot.hour}, Slots: ${slot.slot}`}</li>
                            ))}
                        </ul>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Processing...' : (isEditMode ? 'Update Schedule' : 'Create Schedule')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ScheduleModal;