import React, { useEffect, useState } from 'react';
import totalAppointmentsIcon from "../assets/images/TotalAppointment.png";
import todaysAppointmentsIcon from "../assets/images/TodayAppointment.png";
import upcomingAppointmentsIcon from "../assets/images/UpcomingAppointment.png";
import completedAppointmentsIcon from "../assets/images/VideoAppointment.png";
import { FaPlus, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import './doctor.css';
import ScheduleCard from '../components/schedule-box';
import AppointmentCard from '../components/appointment';
import ScheduleModal from './ScheduleModal';

import axios from 'axios';

const generateDates = (startDate, numDays) => {
    const dates = [];
    const currentDate = new Date(startDate);

    for (let i = 0; i < numDays; i++) {
        const day = currentDate.toLocaleDateString('en-GB', { weekday: 'short' });
        const date = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
        dates.push({ day, date });
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
};

const Dashboard = () => {

    const [doctorId, setDoctorId] = useState(localStorage.getItem('docId') || '');

    const [dates, setDates] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const numVisibleDates = 10;

    let [selectedDate, setSelectedDate] = useState(null);
    const [doctorDetails, setDoctorDetails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scheduleData, setScheduleData] = useState([]);
    const [dashData, setDashData] = useState([]);
    const [appointmentData, setAppointmentData] = useState([]);

    const handleOpenModal = () => setIsModalOpen(true);

    useEffect(() => {
        const startDate = new Date();
        const generatedDates = generateDates(startDate, 30);
        setDates(generatedDates);
        setSelectedDate(generatedDates[0].date);

        if (!doctorId) {
            console.error('No doctor ID found in local storage');
            return;
        }

        fetchDashData();
        fetchAppointmentData();
        fetchDoctorDetails();
        fetchScheduleData();
    }, [doctorId]);

    const fetchDoctorDetails = async () => {
        try {
            const response = await axios.get(`http://localhost:5000/docter/getById`, {
                params: { id: doctorId }
            });
            setDoctorDetails(response.data[0]);
        } catch (error) {
            console.error('Error fetching doctor details:', error);
        }
    };

    const fetchScheduleData = async () => {
        try {
            const response = await axios.get(`http://localhost:5000/docter/scheduleById`, {
                params: { id: doctorId }
            });
            if (response.data) {
                setScheduleData(response.data);
            } else {
                console.error('Unexpected API response format');
            }
        } catch (error) {
            console.error('Failed to fetch schedule', error);
        }
    };

    const fetchDashData = async () => {
        try {
            const response = await axios.get(`http://localhost:5000/docter/dashboard`, {
                params: { id: doctorId }
            });
            if (response.data) {
                setDashData(response.data);
            } else {
                console.error('Unexpected API response format');
            }
        } catch (error) {
            console.error('Failed to fetch Appointment', error);
        }
    };

    const fetchAppointmentData = async () => {
        try {
            const response = await axios.get('http://localhost:5000/docter/appointmentById', {
                params: { id: Number(doctorId) }
            });

            if (Array.isArray(response.data)) {
                const appointmentsWithPatients = await Promise.all(
                    response.data.flatMap(appointmentGroup =>
                        appointmentGroup.info.map(async (info) => {
                            try {
                                const patientResponse = await axios.get('http://localhost:5000/users/getById', {
                                    params: { id: 41117 }
                                });
                                return { ...info, patient: patientResponse.data };
                            } catch (error) {
                                console.error('Failed to fetch patient data', error);
                                return { ...info, patient: null }; // Return null patient on error
                            }
                        })
                    )
                );

                setAppointmentData(response.data.map(appointmentGroup => ({
                    ...appointmentGroup,
                    info: appointmentGroup.info.map((info, index) => ({
                        ...info,
                        patient: appointmentsWithPatients[index].patient
                    }))
                })));
                console.log(appointmentsWithPatients);
            } else {
                console.error('Unexpected API response format');
            }
        } catch (error) {
            console.error('Failed to fetch schedule', error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        fetchScheduleData();
    }

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = Math.max(prevIndex - 1, 0);
            const selectedIndex = dates.findIndex(date => date.date === selectedDate);
            const newSelectedDate = dates[Math.max(selectedIndex - 1, 0)].date;
            setSelectedDate(newSelectedDate);
            console.log(dates[0].date);
            return newIndex;
        });
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = Math.min(prevIndex + 1, dates.length - numVisibleDates);
            const selectedIndex = dates.findIndex(date => date.date === selectedDate);
            const newSelectedDate = dates[Math.min(selectedIndex + 1, dates.length - 1)].date;
            setSelectedDate(newSelectedDate);
            return newIndex;
        });
    };

    const handleDateClick = (index) => {
        const absoluteIndex = currentIndex + index;
        selectedDate = dates[absoluteIndex].date;
        setSelectedDate(selectedDate)
    };

    const filteredScheduleData = scheduleData.find(schedule => {
        const scheduleDate = new Date(schedule.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
        return scheduleDate === selectedDate;
    })?.info || [];

    const handleScheduleDeleted = () => {
        fetchScheduleData();
    };

    // const handleSwipePrev = () => {
    //     setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    // };

    // const handleSwipeNext = () => {
    //     setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, dates.length - numVisibleDates));
    // };

    // const swipeHandlers = useSwipeable({
    //     onSwipedLeft: () => handleNext(),
    //     onSwipedRight: () => handlePrev(),
    //     preventDefaultTouchmoveEvent: true,
    //     trackMouse: true
    // });

    return (
        <div className="container mt-2">
            <div className="container-fluid">
                <div className="mb-3 topHeading">
                    Hello, <strong>Dr. {doctorDetails.name}</strong>
                </div>
                <div className="row appointment-summary">
                    <div className="col-12 col-md-3 mb-3">
                        <div className="summary-box h-100 d-flex flex-column justify-content-center">
                            <div className="d-flex align-items-center">
                                <img src={totalAppointmentsIcon} alt="Total Appointments" className="summary-icon" />
                                <div className="d-flex summary-text">
                                    <p>{dashData.totalAppointments}</p>
                                    <h5>Total Appointments</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mb-3">
                        <div className="summary-box h-100 d-flex flex-column justify-content-center">
                            <div className="d-flex align-items-center">
                                <img src={todaysAppointmentsIcon} alt="Today's Appointments" className="summary-icon" />
                                <div className="summary-text">
                                    <p>{dashData.totalAppointmentsToday}</p>
                                    <h5>Today's Appointments</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mb-3">
                        <div className="summary-box h-100 d-flex flex-column justify-content-center">
                            <div className="d-flex align-items-center">
                                <img src={upcomingAppointmentsIcon} alt="Upcoming Appointments" className="summary-icon" />
                                <div className="summary-text">
                                    <p>{dashData.totalClinicBookings}</p>
                                    <h5>Clinic Appointments</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mb-3">
                        <div className="h-100 d-flex flex-column justify-content-center">
                            <div className="d-flex align-items-center">
                                <img src={completedAppointmentsIcon} alt="Completed Appointments" className="summary-icon" />
                                <div className="summary-text">
                                    <p>{dashData.totalVideoBookings}</p>
                                    <h5>Video Appointments</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-schedule-container mt-4">
                    <span className="my-schedule-text">My Schedule</span>
                    <button className="add-schedule-button" onClick={handleOpenModal}>
                        <FaPlus className="add-icon" />
                        Add Schedule for a Date
                    </button>
                </div>
                {isModalOpen && (
                    <ScheduleModal isOpen={isModalOpen} onClose={handleCloseModal} onSuccess={fetchScheduleData} />
                )}
                <div className="date-slider mt-4">
                    {dates.length > 0 && (
                        <>
                            <FaChevronLeft
                                className={`slider-arrow ${selectedDate === dates[0].date ? 'disabled' : ''}`}
                                onClick={selectedDate === dates[0].date ? null : handlePrev}
                            />
                            <div className="date-container">
                                {dates.slice(currentIndex, currentIndex + numVisibleDates).map((date, index) => (
                                    <div
                                        key={index}
                                        className={`date-item ${selectedDate === date.date ? 'selected' : 'unselected'}`}
                                        onClick={() => handleDateClick(index)}
                                    >
                                        {date.day}, {date.date}
                                    </div>
                                ))}
                            </div>
                            <FaChevronRight
                                className={`slider-arrow ${selectedDate === dates[dates.length - 1].date ? 'disabled' : ''}`}
                                onClick={selectedDate === dates[dates.length - 1].date ? null : handleNext}
                            />
                        </>
                    )}
                </div>
                <div className='scroll-horizontal mt-3'>
                    {filteredScheduleData.map((info, index) => (
                        <ScheduleCard
                            key={index}
                            total={info.totalslots}
                            booked={info.bookedClinic + info.bookedVideo}
                            available={info.availableSlots}
                            clinic={info.bookedClinic}
                            video={info.bookedVideo}
                            time={info.time}
                            scheduleId={info._id}
                            onScheduleDeleted={handleScheduleDeleted}
                        />
                    ))}
                </div>
                <div className="my-schedule-container mt-4">
                    <span className="my-schedule-text">Latest Appointments</span>

                </div>
                <div className='scroll-horizontal mt-3'>
                    {appointmentData.flatMap((appointmentGroup) =>
                        appointmentGroup.info.map((appointment) => (
                            <AppointmentCard
                                key={appointment._id}
                                name={appointment.patient[0]?.fullName || 'Unknown'}
                                age={appointment.patient[0]?.ageGroup || 'N/A'}
                                gender={appointment.patient[0]?.gender || 'N/A'}
                                phone={appointment.patient[0]?.phoneNumber || 'N/A'}
                                time={appointment.time}
                                date={new Date(appointment.date).toLocaleDateString('en-GB')}
                                type={appointment.type}
                                history={appointment.patient[0]?.previousHistory || 'N/A'}
                            />
                        ))
                    )}
                </div>
                <div>

                </div>
            </div>
        </div>
    );
};

export default Dashboard;
